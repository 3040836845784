import React from 'react';
import styled from 'styled-components';
import PortableText from '@sanity/block-content-to-react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { serializers } from '../components/Shared/utils/serializers';
import { graphql, navigate } from 'gatsby';
import LayoutWhite from '../components/Shared/layoutWhite';

const ProjectDescriptionSection = styled.section`
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin-bottom: 40px;
  margin-top: 20px;

  @media screen and (max-width: 600px) {
    margin-left: 0;
    width: 80%;
  }
`;

const ProjectNameHeading = styled.h3`
  font-size: 30px;
  margin-top: 0;

  @media screen and (max-width: 600px) {
    font-size: 24px;
  }
`;

const ProjectDescription = styled.div`
  font-size: 18px;
`;

const SocialIconStyle = {
  marginLeft: '5px',
};

const ContentDivStyled = styled.div`
  width: 70%;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const MainSection = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const useStyles = makeStyles(() => ({
  buttonStyle: {
    '& span.buttonEmoji': {
      marginRight: '5px',
    },
    marginBottom: '20px',
  },
}));

const ProjectTemplate = ({ data }) => {
  const project = data.sanityProject;
  const classes = useStyles();
  const socialImage = getImage(project.projectSocials.social.socialLogo.asset);

  return (
    <LayoutWhite>
      <MainSection>
        <ProjectDescriptionSection>
          <ContentDivStyled>
            <Button
              variant='contained'
              color='primary'
              onClick={() => navigate('/projects')}
              className={classes.buttonStyle}
            >
              <span
                className='buttonEmoji'
                role='img'
                aria-label='Left arrow emoji'
              >
                ⬅️
              </span>
              Go back to all projects
            </Button>
            <ProjectNameHeading>{project.projectName}</ProjectNameHeading>
            <ProjectDescription>
              <PortableText
                blocks={project._rawProjectLongDescription}
                serializers={serializers}
              />
            </ProjectDescription>

            {project.projectSocials.socialProjectLink !== null && (
              <Link
                href={project.projectSocials.socialProjectLink}
                target='_blank'
                rel='noreferrer'
              >
                Project Repository
                <GatsbyImage
                  image={socialImage}
                  alt={project.projectSocials.social.socialLogo.alt}
                  style={SocialIconStyle}
                />
              </Link>
            )}
          </ContentDivStyled>
        </ProjectDescriptionSection>
      </MainSection>
    </LayoutWhite>
  );
};

export const singleProjectEntry = graphql`
  query singleProjectQuery($id: String!) {
    sanityProject(_id: { eq: $id }) {
      _id
      projectName
      projectShortDescription
      _rawProjectLongDescription
      projectSocials {
        socialProjectLink
        social {
          socialName
          socialLogo {
            caption
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 20, height: 20)
            }
          }
        }
      }
      projectSlug {
        current
      }
    }
  }
`;

export default ProjectTemplate;
